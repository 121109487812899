// Component states
import states from './style-navbar-modifier.states.js'

// Components
import AlignHorizontalContentModifier from '../align-horizontal-content-modifier/Align-horizontal-content-modifier.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-navbar-mj'

// Vue@Properties
const props = {
  conf: Object,
  mjml: Object
}
// Vue@Properties

// Vue@subComponents
const components = {
  AlignHorizontalContentModifier,
  custColorPicker
}
// Vue@subComponents

// Vue@data
const data = function () {
  return {
    states
  }
}
// Vue@data

// Methods
const methods = {

  /**
   * Generate quote for composed font to avoid
   * MJML issue as "Arial Black" becoming "Arial #000"
   * @param {String} fonts (Font list as "Arial Black, Arial-Black, Arial")
   */
  quoteComposedFontName (fonts) {
    return fonts.split(',').map(f => {
      const isComposedName = f.trim().split(' ').length > 1
      return isComposedName ? `'${f.trim()}'` : f.trim()
    }).join()
  },

  // Func@updateMenuColor
  updateMenuColor (val) {
    this.linksColor = val
    for (const i in this.mjml.chidren) {
      this.mjml.chidren[i].attributes.color = val
    }
  },
  // Func@updateMenuColor

  // Func@updateIconColor
  updateIconColor (val) {
    this.mjml.attributes['ico-color'] = val
  }
  // Func@updateIconColor
}

// Computed Methods
const computed = {
  linksColor: {
    get () {
      return this.mjml.children[0].attributes.color
    },
    // setter
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].attributes, 'color', newValue)
      }
    }
  },

  fontSize: {
    get () {
      return parseInt(this.mjml.children[0].attributes['font-size'], 10)
    },
    // setter
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].attributes, 'font-size', `${newValue}px`)
      }
    }
  },

  lineHeight: {
    get () {
      return parseInt(this.mjml.children[0].attributes['line-height'], 10)
    },
    // setter
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].attributes, 'line-height', `${newValue}px`)
      }
    }
  },

  fontFamily: {
    get () {
      return this.mjml.children[0].attributes['font-family']
    },
    // setter
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].attributes, 'font-family', newValue)
      }
    }
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components
}
