// Configs
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import userConfig from '@/assets/config/user/user.conf'

// Libraries
import {
  orderBy as _orderBy
} from 'lodash-es'

const fonts = _orderBy([
  ...userConfig.fonts.map(f => f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, arial` : `${f.name}, arial`),
  ...textDefaultStyle.fonts.imported.map(f => {
    const fallback = f.custom_fallback ? f.custom_fallback : 'arial'
    return f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, ${fallback}` : `${f.name}, ${fallback}`
  }),
  ...textDefaultStyle.fonts.native
])

export default {
  heightAuto: true,
  maxWidthPixel: 1000,
  tempHeight: '42px',
  fontFamily: {
    options: [
      'default',
      ...fonts
    ]
  }
}
